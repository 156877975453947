.formWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;

  @media (max-width:1024px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.formRow {
  display: flex;
  gap: 16px;
  width: 60%;

  @media(max-width:1024px) {
    width: 100%;
  }

  @media(max-width:560px) {
    width: 100%;
    flex-wrap: wrap;
  }

  & select {
    border: 1px solid #5b4c23;
    padding: 4px 16px 4px 16px;
    color: #5b4c23;
    font-family: 'Karla', sans-serif;
    letter-spacing: -0.20000000298023224px;
    background: #F2E9E0;
    text-align: center;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    background-image: none !important;
    max-height: 40px;
    min-height: 40px;
  }
}

.inputSearch {
  max-width: 384px;
  width: 40%;
  position: relative;

  @media(max-width:1024px) {
    width: 100%;
  }

  &:after {
    content: '';
    position: absolute;
    width: 26px;
    height: 21px;
    display: block;
    background-image: url("data:image/svg+xml,%3Csvg width='21' height='21' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.7778 15.7778L20 20M1 9.44444C1 11.6841 1.88968 13.8319 3.47332 15.4156C5.05696 16.9992 7.20484 17.8889 9.44444 17.8889C11.6841 17.8889 13.8319 16.9992 15.4156 15.4156C16.9992 13.8319 17.8889 11.6841 17.8889 9.44444C17.8889 7.20484 16.9992 5.05696 15.4156 3.47332C13.8319 1.88968 11.6841 1 9.44444 1C7.20484 1 5.05696 1.88968 3.47332 3.47332C1.88968 5.05696 1 7.20484 1 9.44444Z' stroke='%23383F52' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    top: 10px;
    left: 10px;
    background-position: center;
  }

  & input {
    border: 1px solid #5b4c23;
    background: #fff;
    border-radius: 8px;
    padding-left: 45px;
    min-height: 40px;
  }
}

.submitButton {
  padding: 5px;
  min-height: 40px;
}