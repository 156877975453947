@import '../../styles/customMediaQueries.css';

.error {
  margin-top: 5px;

  @media (--viewportMedium) {
    margin-top: 4px;
  }

  @media (--viewportLarge) {
    margin-top: 3px;
  }
}

.error {
  color: var(--colorFail);
}

.aside {
  box-shadow: none;
}

.topContent {
  background: #fff;
  /* flex-wrap: wrap; */
  border-bottom: 1px solid var(--colorGrey100);
  /* padding: 30px; */
  padding: 34px;
  @media (--viewportLarge) {
    max-width: 1170px;
    margin: 24px auto;
    width: 100%;
    padding: 33px 95px;
  }

  @media (min-width:1500px) {
    max-width: 1399px;
    margin: 0 auto;
    margin-top: 35px;
  }
}

.asideContent {
  width: 100%;
  display: flex;
  flex-direction: column;


  @media (--viewportLarge) {
    flex-direction: row;
    align-items: center;
    border-bottom: none;
    gap: 28px;
  }

  & .imageWrapper {
    display: flex;
    gap: 28px;

    @media (max-width:560px) {
      flex-direction: column;
      gap: 0;
    }
  }
}

.totalProducts {
  color: var(--Light-Text, #5f635c);
  font-family: 'Josefin Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  margin: 0 0 19px;
  padding: 0;
}

.shareButtons {
  display: flex;
  gap: 19px;

  @media (max-width:560px) {
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 15px;
  }
}

.primaryBtn {
  border-radius: 10px;
  background-color: #5b4c23;
  color: #fcf8f2;
  text-align: center;
  font-family: 'Josefin Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 2px;
  text-transform: uppercase;
  width: 118px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  min-height: auto;

  &:hover,
  :focus {
    border: 2px solid #a28930;
  }
}

.followedButton {
  border-radius: 10px;
  background-color: #A4C3E6;
  border: 2px solid #A4C3E6;
  color: #4A2A18;
  text-align: center;
  font-family: 'Josefin Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 2px;
  text-transform: uppercase;
  width: 118px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  min-height: auto;

  &:focus,
  hover {
    background-color: #A4C3E6;
  }
}

.secondaryBtn {
  border-radius: 10px;
  border: 2px solid #5b4c23;
  background: rgba(255, 255, 255, 0);
  padding: 12px 28px;
  color: #5b4c23;
  text-align: center;
  font-family: 'Josefin Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 2px;
  text-transform: uppercase;
  cursor: pointer;
  min-height: auto;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 140px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -75px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.shareButton {
  border-radius: 10px;
  border: 2px solid #5b4c23;
  background: rgba(255, 255, 255, 0);
  padding: 12px 28px;
  color: #5b4c23;
  text-align: center;
  font-family: 'Josefin Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 2px;
  text-transform: uppercase;
  cursor: pointer;
  min-height: auto;

  /* color: #A28930;
  background-color: #ffffff;
  text-align: center;
  font-family: "Josefin Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 2px;
  border: 2px solid #A28930;
  border-radius: 10px;
  padding: 13px 25px;
  text-transform: uppercase;
  background-color: #fff;
  min-height: auto; */

}

.reviewsWrapper {
  margin: 26px 0 20px;
  color: #000;
  text-align: center;
  font-family: 'Josefin Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 2px;
  text-decoration-line: underline;
  text-transform: uppercase;
  display: flex;
  gap: 18px;
  align-items: center;
}

.deliveryStatus {
  display: flex;
  gap: 4px;
  color: #5f635c;
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.305px;
}

.contentRight {
  justify-content: flex-start;
  align-items: flex-start;

  @media (--viewportLarge) {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: flex-end;
  }
}

.details {
  max-width: 750px;
  max-height: 86px;
  overflow-y: auto;
  padding-right: 8px;
  color: #1E2915;
  font-family: 'Karla', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;

  /* width */
  &::-webkit-scrollbar {
    width: 3px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #dfdfdf;
    border-radius: 15px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #4A2A18;
    border-radius: 15px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #7f7f7f;
  }
}

.upArrow {
  display: flex;
  align-items: center;
  justify-content: center;
}

.downArrow {
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(180deg);
}

.avatar {
  margin: 30px 26px 29px 0;
  flex-shrink: 0;

  @media (--viewportLarge) {
    margin: 0 0px 0px 0;
    width: 149px;
    height: 149px;
    border-radius: 3px;
  }
}

.mobileHeading {
  flex-shrink: 0;
  margin: 60px 0 0 0;

  @media (--viewportMedium) {
    margin: 49px 0 0 0;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.editLinkMobile {
  margin-top: 17px;

  /* Pull the link to the end of the container */
  margin-left: auto;

  @media (--viewportMedium) {
    margin-top: 20px;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.editLinkDesktop {
  display: none;

  @media (--viewportLarge) {
    display: inline;
    padding: 6px 0 2px 0;
  }
}

.desktopHeading {
  display: none;

  @media (--viewportLarge) {
    display: block;
    margin: 0 0 8px 0;
    color: #1e2915;
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    /* 123.077% */
    letter-spacing: -1px;
    padding: 0;
  }
}

.bio {
  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;
  margin: 5px 0 24px 0;

  @media (--viewportMedium) {
    margin: 4px 0 51px 0;
  }

  @media (--viewportLarge) {
    margin: 0 0 56px 0;
    max-width: 600px;
  }
}

.listingsContainer {
  composes: clearfix from global;
  padding-bottom: 23px;

  @media (--viewportMedium) {
    padding-bottom: 22px;
  }
}

.listingsTitle {
  color: var(--colorGrey700);
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 48px;
    margin-bottom: 8px;
  }

  @media (--viewportLarge) {
    margin-top: 56px;
    margin-bottom: 8px;
  }
}

.withBioMissingAbove {}

.listings {
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 26px;
  margin-top: 47px;
}

.listing {
  width: 100%;

  /* Single column in mobile */

  @media (--viewportMedium) {
    /* Two columns in desktop */
    width: calc(25% - 20px);
    margin-bottom: 36px;

    /* Horizontal space */
    /* &:nth-of-type(odd) {
      margin-right: 12px;
    }
    &:nth-of-type(even) {
      margin-left: 12px;
    } */

    margin: 0;
    padding: 0;

    /* Vertical space */
    margin-top: 0;
  }
}

.mobileReviewsTitle {
  margin: 24px 0 26px 0;
}

.mobileReviews {
  /* border-top: 1px solid var(--colorGrey100); */
  margin-bottom: 36px;

  &>h2 {
    color: #1D1D1F;
    font-family: 'Inter';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.348px;
  }
}

.desktopReviews {
  /* border-top: 1px solid var(--colorGrey100); */

  @media (--viewportMedium) {
    margin-bottom: 48px;
  }

  @media (--viewportLarge) {
    margin-bottom: 0;
  }
}

.desktopReviewsWrapper {
  /* max-width: 600px; */
  margin-top: 28px;

  &>ul {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

.desktopReviewsTitle {
  composes: textLarge from global;
  margin-bottom: 0;
}

.desktopReviewsTabNav {
  margin-bottom: 32px;

  &>div {
    &>button {
      padding: 0;

      &>h3 {
        color: #1D1D1F;
        font-family: 'Inter';
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: -0.348px;
      }
    }
  }
}

.layoutSingleColumn {
  background: #f2e9e0;
}

.mainContent {
  background: #fff;
  padding: 48px 95px;

  @media (--viewportLarge) {
    max-width: 1170px;
    margin: 24px auto;
  }

  @media(min-width:1500px) {
    max-width: 1400px;
    width: 100%;
    background: #fff;
    margin: 26px auto;
  }

  @media(max-width:1024px) {
    padding: 38px 34px;
  }


  @media (max-width:460px) {
    padding: 24px;
    margin: 0 auto;
  }

  & .gridContent {
    & :global(.ais-Hits) {

      & :global(.ais-Hits-list) {
        display: flex;
        width: 100%;
        gap: 27px;
        flex-wrap: wrap;
        padding: 0;
        max-width: 100%;
        width: 100%;
      }

      & :global(.ais-Hits-item) {
         list-style-type: none;
         width: calc(23% - 12px);
      
         @media(max-width:1360px) {
           width: calc(32% - 12px);
         }
      
         @media(max-width:1024px) {
           width: calc(49% - 12px);
         }
        &>div {
          & a {
            &:hover {
              text-decoration: none;
            }

            &>div {
              & img {
                /* width: 100%;
                      height: 100%; */
              }
            }
          }
        }
      }
    }
  }
}