.mainWrapper {
    background-color: #Fcf8f2;
}

.container {
    max-width: calc(1440px + 60px);
    padding: 100px 34px 0 34px;
    width: 100%;
    margin: 0 auto;

    @media(max-width:768px) {
        padding: 50px 24px 0 24px;
    }
}

.searchfilter {
    display: flex;
    gap: 20px;
    justify-content: space-between;

    @media(max-width:1200px) {
        flex-wrap: wrap;
    }
}

.profilesearchBox {
    &>div {
        position: relative;

        & form {
            & input {
                background: #fff;
                border: 1px solid #5b4c23;
                border-radius: 8px;
                min-height: 40px;
                padding-left: 45px;
                min-width: 384px;
                padding-left: 60px;
                padding-right: 60px;
                font-family: 'Karla', sans-serif;
                font-size: 16px;
                font-style: italic;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: -0.34823527932167053px;
                text-align: left;
                color: #5B4C23;

                @media(max-width:1500px) {
                    min-width: 468px;
                }

                @media(max-width:1200px) {
                    min-width: 440px;
                }

                @media(max-width:768px) {
                    min-width: 100%;
                }

            }

            &>button {
                border: none;
                cursor: pointer;
            }

            & :global(.ais-SearchBox-submit) {
                position: absolute;
                top: 6px;
                left: 14px;

                & svg {
                    width: 24px;
                    height: 24px;

                    & path {
                        fill: #5B4C23;
                    }
                }
            }

            & :global(.ais-SearchBox-reset) {
                position: absolute;
                top: 6px;
                right: 6px;

                & svg {
                    width: 18px;
                    height: 18px;

                    & path {
                        fill: #5B4C23;
                    }
                }
            }
        }
    }
}

.rightFilters {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
}

.filterDesktop {
    display: flex;
    gap: 6px;
    flex-wrap: wrap;

    & :global(.ais-Panel-body) {
        & select {
            border: 1px solid #5B4C23;
            font-family: 'Karla', sans-serif;
            font-size: 13px;
            font-weight: 500;
            color: #5B4C23;
            line-height: 16px;
            letter-spacing: -0.20000000298023224px;
            text-align: center;
            background: #F2E9E0;
            min-height: 40px;
            width: 180px;
            padding: 0 20px;
            border-radius: 4px;
            position: relative;
            cursor: pointer;
        }
    }
}

.priceInput {
    &>div {
        & button {
            border: 1px solid #5B4C23;
            font-family: 'Karla', sans-serif;
            font-size: 13px;
            font-weight: 500;
            color: #5B4C23;
            line-height: 16px;
            letter-spacing: -0.20000000298023224px;
            text-align: center;
            background: #F2E9E0;
            min-height: 47px;
            width: fit-content;
            padding: 0 20px;
            border-radius: 4px;
            position: relative;
            cursor: pointer;
        }

        & :global(.ais-RangeInput) {
            position: absolute;
            background-color: #fff;
            padding: 14px;
            box-shadow: 0 8px 16px 0 #0000004d;
            z-index: 10;

            & form {
                display: flex;
                align-items: center;
                gap: 4px;

                & button {
                    min-height: 40px;
                    font-weight: 600;
                    background-color: #5B4C23;
                    cursor: pointer;
                    color: #ffffff;
                }
            }
        }
    }
}

.shortWrapper {
    & select {
        border: none;
        font-family: 'Inter';
        font-size: 13px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.3047058880329132px;
        color: #4A4A4A;
        text-align: center;
        background: transparent;
        min-height: 40px;
        width: 170px;
        padding: 0 20px;
        border-radius: 4px;
        background: #f2e9e0;
        background-image: none !important;
        border: 1px solid #5b4c23;
        color: #5b4c23;
        font-family: 'Karla', sans-serif;

        @media(max-width: 768px) {
            border: none;
            min-height: 47px;
            padding-left: 0;
            width: 100px;
        }
    }
}

.clearFilter {
    & button {
        border: none;
        /* font-family: 'Karla', sans-serif; */
        font-size: 15px;
        font-weight: 500;
        color: #ffffff;
        line-height: 16px;
        letter-spacing: -0.20000000298023224px;
        text-align: center;
        background-color: var(--button-bg-color);
        min-height: 40px;
        width: 170px;
        padding: 0 20px;
        border-radius: 4px;
        position: relative;
        cursor: pointer;
    }
}

.conatinerResult {
    margin-top: 25px;

    & .filterResult {
        & span {
            font-family: 'Josefin Sans';
            font-size: 16px;
            font-weight: 500;
            line-height: 16px;
            letter-spacing: -0.20000000298023224px;
            text-align: left;
            color: #5B4C23;
        }
    }
}



.mobileFilters {
    display: flex;
    gap: 10px;

    & .mobileButton {
        border: 1px solid #5B4C23;
        font-family: 'Karla', sans-serif;
        font-size: 13px;
        font-weight: 600;
        color: #5B4C23;
        line-height: 16px;
        letter-spacing: -0.20000000298023224px;
        text-align: center;
        background: #F2E9E0;
        min-height: 47px;
        width: fit-content;
        padding: 0 20px;
        border-radius: 4px;
        position: relative;
        cursor: pointer;

        & select {
            color: #5B4C23;
            padding-right: 0;
            font-size: 13px;
            font-weight: 600;
        }
    }
}

.filterModal {
    &>div>div>div {
        margin-top: 50px;
    }
}

.paginationLink {
    margin-top: 100px;

    &>div {
        &>ul {
            align-items: center;
            display: flex;
            gap: 18px;
            justify-content: center;
            margin-top: 45px !important;
            margin-bottom: 0 !important;
            padding: 0;
            position: relative;
            background: #fff;

            & li {

                &:global(.ais-Pagination-item--firstPage),
                &:global(.ais-Pagination-item--lastPage) {
                    display: none;
                }

                &:global(.ais-Pagination-item--previousPage) {
                    left: 14px;
                    position: absolute;
                    cursor: pointer;

                    &>a {
                        color: #A58930;
                        font-size: 28px;
                    }

                    &>span {
                        color: #A58930;
                        font-size: 28px;
                        padding: 14px 0;
                    }
                }

                &:global(.ais-Pagination-item--nextPage) {
                    right: 14px;
                    position: absolute;
                    cursor: pointer;

                    &>a {
                        color: #A58930;
                        font-size: 28px;
                    }

                    &>span {
                        color: #A58930;
                        font-size: 28px;
                    }
                }

                &:global(.ais-Pagination-item--page) {
                    border-bottom: 3px solid #ffffff;
                    padding: 14px 0;
                    width: 30px;
                    cursor: pointer;
                    text-align: center;

                    &>a {
                        color: #4A4A4A;
                        font-family: 'Inter';
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 24px;
                        letter-spacing: -0.34823527932167053px;
                        text-align: left;
                        text-decoration: none;
                        /* width: 30px; */
                    }
                }

                &:global(.ais-Pagination-item--selected) {
                    border-bottom: 3px solid #1D1D1F;
                    width: 30px;
                    cursor: pointer;
                    text-align: center;
                    padding: 14px 0;

                    &>a {
                        font-family: 'Karla', sans-serif;
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 24px;
                        letter-spacing: -0.34823527932167053px;
                        text-align: left;
                        text-decoration: none;
                        /* width: 30px; */
                        color: #1D1D1F;
                    }
                }
            }
        }
    }
}

.moreFilterModal {
    & h4 {
        text-align: center;
        font-size: 24px;
        margin: 24px 0;
    }

    & .moreFilterModalContent {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        width: 100%;
        place-items: center;
    }
}

& .seeAllLisitng {
    width: 253px;
    margin: 36px auto;
    background-color: #A28930;
    font-family: "Josefin Sans";
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 2px;
    text-align: center;
    color: #FCF8F2;
    display: flex;
    text-transform: uppercase;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    border: 1px solid #A28930;
    padding: 21px 0;

    @media(max-width:560px) {
      width: 100%;
      padding: 18px 0;
    }

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
  